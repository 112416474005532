<template>
  <div>
    <static-fullscreen-card v-show="!idItemShow">
      <template v-slot:header></template>
      <template v-slot:actions>
        <v-btn color="primary" @click="$refs.table.updateData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title></v-btn>
        <v-btn v-if="getAccess(`${m.accessName}.create`)" color="primary" @click="createNew()" title="Создать"
          ><btn-title icon="far fa-plus-square">Создать</btn-title></v-btn
        >
      </template>
      <portal to="v-main">
        <s-edit-form v-model="showEditDialog" v-if="showEditDialog" :api="url" :m="m" :config="configHead" :title="configHead.title" :id="idEdit" />
      </portal>
      <a-table-f-api ref="table" :api="url" :model="model" :useQuery="true" :defaults="defaults" @click="onClickRow($event)" />
    </static-fullscreen-card>
    <ViewItem v-if="idItemShow" :idShow="idItemShow" @close="itemShowClose($event)" />
  </div>
</template>

<script>
import mainView from "./mainView";
import { getAccess } from "@/components/mixings";
export default {
  mixins: [mainView,getAccess],
  components: {
    ViewItem: () => import("./views/shopView"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      title: "",
      m: this.$store.getters["config/get"].models.shop,

      defaults: {
        // filters: { status: 1 },
        sort: { key: "id", order: "DESC" },
      },
    };
  },
  created() {
    this.$root.title = this.m.title;
  },
  computed: {
    configHead() {
      return this.m.config.default || {};
    },
    url() {
      return this.m.api;
    },
    model() {
      let model = JSON.parse(JSON.stringify(this.m.list));
      model.forEach(el => {
        if (el?.sourceList) {
          el.options = this.m[el.sourceList];
        }
      });
      return model;
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    onClickRow(d) {
      // this.$router.push({ path: "/modal" });
      //this.idItemShow = d.row.id;
      //this.showDetail = true;
      //this.showEditDialogFun(d.row.id);
      this.$router.push({ name: this.m.routeName, params: { id: d.row.id } });
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
